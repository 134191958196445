























import { LOGOUT_USER, USER_STATE } from "@/store/modules/auth/constants";
import { UserState } from "@/store/modules/auth/interfaces";
import Vue from "vue";
import { mapActions, mapMutations } from "vuex";
export default Vue.extend({
  name: "PendingVerification",
  data() {
    return {
      success_animation: require("@/assets/illustrations/onboarding-success.json")
    };
  },
  async created() {
    this.success_animation = JSON.stringify(this.success_animation);
  },
  methods: {
    ...mapMutations("auth", {
      update_user_state: USER_STATE
    }),
    ...mapActions("auth", {
      logout: LOGOUT_USER
    }),
    async process_click() {
      this.update_user_state(UserState.PENDING_VERIFICATION);
      await this.logout();
      this.$router.push("/auth/login");
    }
  }
});
